$text : #2e293c;
$secondary : #000000;

html,
body{
    background: rgb(247, 247, 247);
    
}

.testimonial-section {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80%;
    max-width: 1500px;
    margin: 0 auto;

    &:after {
      content: "";
      position: inherit;
      width: 100%;
      height: 65%;
      background: #f2f2f6;
      right: 0;
      bottom: 0;
      opacity: 1;
      z-index: -1;
    }
    .testimonial-container {
      width: 100%;
      min-width: 100%;
      height: 100%;
      position: relative;
      .arrows {
        position: absolute;
        display: flex;
        width: 8%;
        align-items: center;
        justify-content: center;
        bottom: 0;
        top: 0;
        border: none;
        background-color: rgba(0,0,0,0);
        cursor: pointer;
        border-radius: 8px;
        transition: 0.3s ease-in-out;
        &:hover {
          box-shadow: 0px 0px 30px rgba(0, 0, 80, 0.05);
        }
        &.left{
          left:1;
          z-index: 1;
          
        }
        &.right {
          right: 0;
          z-index: 1;
          
        }
        .arrow_sign{
          filter: brightness(100);
        }
      }
      .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 65vh;
        top: 20%;
        .t-image {
          width: 10wh;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          
          ul {
            list-style-type: none;
            display: flex;
            padding:0;
            position: relative;
            overflow: hidden;
            height: 90%;
            width: 25%;
            box-shadow: 0px 0px 40px rgba(94, 94, 94, 0.253);
            
          }
        }
        .t-content {
          
          display: flex;
          align-items: center;
          ul {
            list-style: none;
            
            overflow: hidden;
            padding:0px;
            
            li {
              
              color: $text;
              display: flex;
              align-items: center;
              position: absolute;
              opacity: 0;
              justify-items: center;
            
              .content-inner {
                
                .info {
                  color: rgba(241, 239, 239, 0.774);
                }
                .technology {
                  color: #f2f2f6;
                }
                .title-1 {
                  color: #f2f2f6;
                }
              }
            }
          }
        }
      }
    }
  }

  // @media screen and (min-width: 1101px) and (max-width: 1400px){
  //   .t-content{
  //     left: -35% !important;
  //   }
  // }

  @media screen and (min-width: 1601px){
      .inner{
        .t-image{
          ul{
            li{
              height: 460px;
              width: 340px;
              img{
                height: 460px;
                width: 340px;
              }
            }
          }
        }
      .t-content{
        position: relative;
        width: 500px;
        height: 600px;
        left: -25%;
          ul{
            position: relative;
            width: 500px;
            height: 400px;
              li{
                width: 500px;
              height: 400px;
              .content-inner{
                width: 500px; 
                .info{
                  font-size: 20px;
                  letter-spacing: 0.88px;
                  line-height: 30px;
                  font-weight: 500;
                  margin-bottom: 16px;
                }
                .technology{
                  font-size: 18px;
                  letter-spacing: 0.88px;
                  line-height: 28px;
                  color: $secondary;
                  font-weight: 600;
                }
                .title-1{
                  font-size: 38px;
                  letter-spacing: 0.88px;
                  line-height: 35px;
                  color: $secondary;
                }
              }
              }
          }
      }

  }
}

  @media screen and (max-width: 1600px) {
    .testimonial-container {
      .arrows{
        top:100px;
      }
    .inner{
        flex-direction: column;
        .t-image{
          ul{
            li{
              height: 360px;
              width: 240px;
              img{
                height: 360px;
                width: 240px;
              }
            }
          }
        }
    
    .t-content{
        width: 350px;
          height: 400px;
          
          position: relative;
          top : -10%;
          align-content: center;
          
          

        ul{
            
            width: 350px;
            height: 400px;
            li{
                width: 350px;
                height: 400px;
               
                .content-inner{
                    width: 350px;
                    .info{
                      font-size: 16px;
                  letter-spacing: 0.88px;
                  line-height: 30px;
                  font-weight: 500;
                  margin-bottom: 16px;
                    }
                    .technology{
                      font-size: 14px;
                      letter-spacing: 0.88px;
                      line-height: 28px;
                      color: $secondary;
                      font-weight: 600;
                        }
                    .title-1{
                        font-size: 30px;
                        letter-spacing: 0.88px;
                        line-height: 35px;
                        color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}
    
