@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

.p_style{
    font-family: 'Courier Prime', monospace;
    margin:0;
    top:5%;
    left:1%;
    position: relative;
    
}

.terminal{
    height: 40vh;
    border-style: solid;
    background: black;
    border-radius: 5px;
    position: relative;
    top:50%;
    opacity: 0;;
}

.terminal-title{
    background: rgb(80, 79, 79);
    height: 20px;
    margin:0;
    position: relative;
}

.red{
    width: 13px;
    height: 13px;
    background: rgb(209, 34, 34);
    border-radius: 50%;
    position: relative;
    left:4px;
    top:20%;
  }

.yellow{
    width: 13px;
    height: 13px;
    background: rgb(255, 196, 3);
    border-radius: 50%;
    position: relative;
    left:23px;
    top:-45%; 
}

.green{
    width: 13px;
    height: 13px;
    background: rgb(18, 216, 18);
    border-radius: 50%;
    position: relative;
    left:43px;
    top:-110%;
}
.buttonkey:hover{
    opacity: 0.6;
}


.title{
    position: relative;
    text-align: center;
    top: -200%;
    color: rgb(236, 236, 236);
    align-content: center;
}

