@import url('https://fonts.googleapis.com/css2?family=Carme&display=swap');

body{
  background: #00010a;
}

#root {
  /* background: url(https://i.pinimg.com/originals/44/6e/3b/446e3b79395a287ca32f7977dd83b290.jpg); */
  /* background: #64abfc; */
  background: url('../src/components/assets/wp5848964-macintosh-minimalist-4k-wallpapers.jpg');
  background-repeat: no-repeat, repeat;
  background-clip: inherit;
  background-position: center;
  background-size: cover;
  margin:0px;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  overscroll-behavior: contain ;
  max-width: 1800px;
  margin: 0 auto;
  box-shadow: 0px 0px 60px #141d2af2;
}

#root::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}

/* background of the scrollbar except button or resizer */
#root::-webkit-scrollbar-track {
  background-color:#0c0e18
}
#root::-webkit-scrollbar-track:hover {
  background-color:#0c0e18
}

/* scrollbar itself */
#root::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #0c0e18
}
#root::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
#root::-webkit-scrollbar-button {display:none}

.header-class{
  height: 100vh;
  scroll-snap-align: center;
  position: relative;
}

.first{
  
  background: rgba(0, 0, 0,0.5);
  height: 90%;
  padding: 10% 10% 10% 10%;
  
  
}
.second{
  height: auto;
  scroll-snap-align: center;
  
}
.third{
  background: rgba(0, 0, 0, 0.5);
  height: auto;
  overflow: hidden;
  scroll-snap-align: center;
  opacity: 1;
}
.fourth{
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  scroll-snap-align: center;
}
.contact-form{
  background: white;
  background: rgba(0, 0, 0, 0.705);
  height: auto;
}

.site-footer
{ 
  position: relative;
  width:100%;
  background-color:rgba(31, 28, 28, 0.5);
  padding:35px 0 35px;
  font-size:15px;
  line-height:34px;
  color:#737373;
  
}

.p-style{
  color: rgb(12, 7, 1);
  text-align: center;
  left : 50%;
  top : 50%;
  transform: translate(-50%,-50%);
  position: relative;
  font-size: xx-large;  
}

.slide-style{
  position: relative;
  top:45%;
  text-align: center;
  color: white;
  align-content: center;
  opacity: 1;
}

.title-2{
  position: relative;
  top:3%;
  color: rgb(255, 255, 255);
  font-family: 'Rajdhani', sans-serif;
  font-weight: 550;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 40px;
  text-align: center;
  z-index: 1;
  
}