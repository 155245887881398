@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
html,
body{
    background: white;
}

.skill-content{
    height: 100vh;
    width: 100wh;
    background: rgba(0, 0, 0,0.5);
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin:0;
    
    
    
    .skill-title{
        color:rgb(255, 255, 255);
        position: relative;
        text-align: center;
        opacity: 0;
        z-index: 3;
        
        h4{
            font-family: 'Rajdhani', sans-serif;
            font-weight: 550;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.5);   
            letter-spacing: 0.88px;
        // box-shadow: 0 0 40px rgba(0,0,0,0.55);
        }

    }
    .skill-inner{
        display: flex;
        flex-flow: row wrap;
        margin:0;
        padding: 50px;
        background: inherit;
        // background:rgba(0, 0, 0, 0.904);
        position: relative;
        z-index: 2;     
        justify-content: center;
        overflow:auto;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, .3);  
        backdrop-filter: blur(10px);
        box-shadow: 0 0 15px #ffffff62;

        
        .skill-heading{
            color: #20C20E;
            font-family: 'Pacifico', cursive;
            font-weight: 300;
            

        }
        ul{
            color: rgb(255, 255, 255);
            font-family: 'Rajdhani', sans-serif;
                
                font-weight: 400;
                height: auto;
                line-height: 30px;
                text-shadow: 1px 1px rgba(0, 0, 0, 0.25);

        }
        .languages{
            justify-content: center;
            text-align: center;
            opacity: 0;
            ul{
                list-style-type: none;
                padding: 0;
                width: 180px;
                
            }

        }
        .database{
            justify-content: center;
            text-align: center;
            opacity: 0;
            ul{
                list-style-type: none;
                padding: 0;
                width: 180px;
            }

        }
        .tools{
            justify-content: center;
            text-align: center;
            opacity: 0;
           ul{
            list-style-type: none;
            padding: 0;
            width: 180px;
            
           } 

        }
        .universal-skills{
            justify-content: center;
            text-align: center;
            opacity: 0;
            ul{
                list-style-type: none;
                padding: 0;
                width: 180px;
            }

        }

    }

}

@media screen and (min-width: 800px){
    .skill-title{
        top : 8%;
        h4{
            font-size: 4em;
            font-weight: 500;
        }
    }
    .skill-inner{
        height: 50%;
        width: 70%;
        top:20%;
        left:15%;

        .skill-heading{
            font-size : 250%;
        }
        ul{
            font-size : 120%;
        }
    }
}

@media screen and (max-width: 800px){
    .skill-title{
        top : 2%;
        h4{
            font-size: 3em;
        }
    }
    .skill-inner{
        height: 80%;
        width: 80%;
        top:4%;
        left:10%;
        .skill-heading{
            font-size : 200%;
        }
        ul{
            font-size: 120%;
        }
    }
}