.header-style{
    height: 10%;
    margin: 0;
    background:rgba(2, 2, 2,0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
}

.text{
    
    text-align: center;
    font-size: 30px;
    color:rgb(255, 255, 255);
    font-family: 'Cinzel', serif;
    font-variant: small-caps;
    margin: 0;
}


.button-left{
   
    top: 1.5em;
    left: 5%;
    z-index: 10;
    font-size: small;
    
    display:inline-block;
    padding:0.5em 1em;
    background-color: rgba(53, 15, 15, 0);
    border:0.10em solid #ffffff;
    border-radius: 5px;
    margin:0 0.1em 0.1em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#ffffff;
    text-align:center;
    transition: all 0.15s;
}

.button-left:hover{
    color:#fffbfb;
    background-color: rgba(245, 0, 0, 0.5);
    border-color:#123023;
}

.button-right{
   
    
    font-size: small;
    top : 1.5em;
    right: 5%;
    z-index: 10;
    display:inline-block;
    padding:0.5em 1em;
    background-color: rgba(53, 15, 15, 0);
    border:0.10em solid #ffffff;
    border-radius: 5px;
    margin:0 0.1em 0.1em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#ffffff;
    text-align:center;
    transition: all 0.15s;
}   

.button-right:hover{
    color:#fffbfb;
    background-color: rgba(31, 255, 1, 0.5);
    border-color:#123023;
}

